// @ts-ignore
import { dcFactory } from '@deleteagency/dc';
import { nanoid } from 'nanoid';
import { deviceObserver } from './device';
import { dynamicStylesService } from './dynamic-styles-service';
import { scrollbarHandler } from './scrollbar-handler';

declare global {
    interface Window {
        appConfig: {
            captchaSiteKey: string;
            portalId: string;
            gaMeasurementID: string;
        };
    }
}

class App {
    private id = nanoid(10);

    public config = window.appConfig || {};

    public isTouch = !document.documentElement.matches('.can-hover');

    public init(): void {
        dcFactory.init();
        this._setScrollbarSize();
        this._watchViewport();
    }

    private _watchViewport(): void {
        this._setViewportSize();
        deviceObserver.subscribeOnResize(this._setViewportSize);
    }

    private _setViewportSize = (): void => {
        dynamicStylesService.setStyles({
            id: this.id + 'v',
            selector: '.body',
            css: `--viewport-height: ${window.innerHeight}px;`,
        });
    };

    private _setScrollbarSize = (): void => {
        dynamicStylesService.setStyles({
            id: this.id + 's',
            selector: '.body',
            css: `--scrollbar: ${scrollbarHandler.getScrollbarSize()}px;`,
        });
    };
}

export const app = new App();
