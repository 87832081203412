module.exports = {
    viewports: {
        'mobile-wide': 580,
        tablet: 768,
        desktop: 1024,
        'desktop-wide': 1280,
        'desktop-ultra': 1440,
    },
    containerMaxWidth: 1170,
};
