import { viewports, containerMaxWidth } from '../../config';

export const constants = Object.freeze({
    CONTAINER_MAX_WIDTH: containerMaxWidth,
    VIEWPORT_WIDTH_DESKTOP_WIDE: viewports['desktop-wide'],
    VIEWPORT_WIDTH_DESKTOP: viewports.desktop,
    VIEWPORT_WIDTH_TABLET: viewports.tablet,
});

export const defaultValues = Object.freeze({
    ACTIVE_CLASS: 'is-active',
});

export const maxPages = Object.freeze({
    MAX_PAGES_MOBILE: 6,
    MAX_PAGES_DESKTOP: 10,
});
