import { DeviceObserver } from '@deleteagency/device-observer';
import { constants } from './enums';

const deviceObserver = new DeviceObserver();

deviceObserver.init({
    mobile: 0,
    tablet: constants.VIEWPORT_WIDTH_TABLET,
    desktop: constants.VIEWPORT_WIDTH_DESKTOP,
    'desktop-wide': constants.VIEWPORT_WIDTH_DESKTOP_WIDE
});

export { deviceObserver };
